import { colors, createTheme } from '@mui/material';
import { getContrastRatio } from '@mui/material/styles';

const white = '#FFFFFE';
const black = '#000000';

const { palette } = createTheme();

const getPrimaryColor = () => {
  // Lógica para obtener el color primario según el entorno
  const environment = process.env.REACT_APP_ENV;

  // console.log('en theme', environment);

  if (environment === 'prototype') {
    return '#5C469C'; // Cambia este color al color deseado para el prototipo
  } else if (environment === 'staging') {
    return '#3C6255'; // Color para el entorno de preparación
  } else if (environment === 'production') {
    return '#203543'; // Color para el entorno de producción
  }
  return '#203543'; // Color por defecto
};

const getPrimaryDarkColor = () => {
  // Lógica para obtener el color primario según el entorno
  const environment = process.env.REACT_APP_ENV;
  if (environment === 'prototype') {
    return '#493684'; // Cambia este color al color deseado para el prototipo
  } else if (environment === 'staging') {
    return '#2e4f44'; // Color para el entorno de preparación
  } else if (environment === 'production') {
    return '#1A2831'; // Color para el entorno de producción
  }
  return '#1A2831'; // Color por defecto
};

export default {
  black,
  white,
  grey: {
    50: '#F3F6FA',
    100: '#EBEEF1',
    200: '#DCE0E4',
    300: '#7F92A7',
    400: '#464646',
    500: '#203543',
    600: '#66788E',
  },
  primary: {
    contrastText: white,
    dark: getPrimaryDarkColor(),
    main: getPrimaryColor(),
    light: '#66788E',
  },
  secondary: {
    contrastText: white,
    // dark: '#137ab4',
    main: '#345EB5',
    // light: '#0db1ff',
  },
  tertiary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: 'rgb(0, 112, 165)',
    light: 'rgb(51, 179, 239)',
    main: '#00A0EC',
  },
  boxes: {
    contrastText: white,
    dark: '#A84448',
    main: '#FF7000',
    light: '#81B214',
    darker: '#93329E',
    lighter: '#00ADB5',
  },
  countingStages: {
    prePoda: '#4EAA00',
    postPoda: '#2D580F',
    preRaleo: '#CC3333',
    postRaleo: '#8E2626',
  },
  speciesGradient: {
    Cerezo: 'linear-gradient(#B2001D, #55001B)',
    Ciruelo: 'linear-gradient(#B56B8D, #8B2B5A)',
    Nectarines: 'linear-gradient(#FF9A80 , #D40306)',
    Durazno: 'linear-gradient(#FFB12D, #BC201A)',
    Carozo: 'linear-gradient(#FFB12D, #705922)',
    Damasco: 'linear-gradient(#fec63a, #944709)',
  },
  gradient: {
    main: 'linear-gradient(0deg, rgba(42,65,79,1) 0%, rgba(82,101,112,1) 100%)',
  },
  mapbutton: {
    main: '#EBEEF1',
  },
  success: {
    contrastText: black,
    dark: '#00A46A',
    main: '#25E299',
    light: '#CDFEE4',
  },
  info: {
    contrastText: white,
    dark: '#0D469B',
    main: '#084EC5',
    light: '#C1E5FF',
  },
  warning: {
    contrastText: black,
    dark: '#E6A832',
    main: '#FFBB38',
    light: '#FFF8EB',
  },
  error: {
    contrastText: white,
    dark: '#772525',
    main: '#A12626',
    light: '#FFD8D8',
  },
  text: {
    primary: '#1A2831',
    secondary: '#203543',
    tertiary: '#66788E',
    link: colors.blue[600],
  },
  background: {
    default: white,
    paper: white,
  },
  icon: colors.grey[500],
  divider: colors.grey[200],
};

export const yearGraphColors = ['#00ADB5', '#81B214', '#93329E', '#FF5722'];

export const varietyGraphColors = [
  '#2A4C60',
  '#084EC5',
  '#81B214',
  '#00ADB5',
  '#A84448',
  '#FF7000',
  '#93329E',
  '#E6A832',
];

export const countStageColors = [
  '#a7a7a7',
  '#4EAA00',
  '#2D580F',
  '#CC3333',
  '#8E2626',
];
